<template>
  <div class="content">
    <div class="Top">
      <div class="top">
        <van-search
          @clear="clearInput"
          @input="getKey"
          @search="onSearch"
          v-model="search"
          placeholder="请输入民宿名称或房源名称"
        >
          <!-- <template #action>
            <div @click="onSearch">搜索</div>
          </template> -->
        </van-search>
        <div class="day" @click="show = true">
          <div class="ru">
            入
            <span class="date">{{ start }}</span>
          </div>
          <div class="li">
            离
            <span class="date">{{ end }}</span>
          </div>
        </div>
      </div>

      <van-dropdown-menu active-color="#fec900">
        <van-dropdown-item
          @change="getSort"
          v-model="sortMode"
          :options="option2"
        />
        <van-dropdown-item title="价格区间" ref="item">
          <div style="display: flex; justify-content: space-between">
            <div style="margin-top: 10px">价格区间</div>
            <div>￥{{ huakuai[0] }} ~ ￥{{ huakuai[1] }}</div>
          </div>
          <van-slider
            v-model="huakuai"
            range
            @change="onChange"
            :min="min"
            :max="max"
            :step="50"
          >
          </van-slider>
          <div
            style="
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
            "
          >
            <div class="tags" v-for="item in PriceList" :key="item.id">
              <van-tag
                round
                type="primary"
                color="#F4F5F7"
                text-color="#606060"
                size="large"
                @click="clickTag(item.id)"
                :class="tagColor === item.id ? 'tag-click' : ''"
              >
                {{ `\xa0\xa0${item.name}\xa0\xa0` }}
              </van-tag>
            </div>
          </div>
          <div
            style="
              padding: 12px 0px;
              display: flex;
              justify-content: space-between;
            "
          >
            <van-button style="width: 27%" block round @click="clear">
              清空
            </van-button>
            <van-button
              style="width: 66%"
              type="danger"
              block
              round
              @click="onConfirm1"
            >
              确认
            </van-button>
          </div>
        </van-dropdown-item>
        <van-dropdown-item title="筛选条件" ref="item1">
          <div style="display: flex; justify-content: space-between">
            <div style="margin-top: 10px">出租类型</div>
          </div>
          <div
            style="
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              margin-top: 12px;
            "
          >
            <div class="tags" v-for="item in rentalTypeList" :key="item.id">
              <van-tag
                round
                type="primary"
                color="#F4F5F7"
                text-color="#606060"
                size="large"
                @click="clickTag1(item.id)"
                :class="tagColor1 === item.id ? 'tag-click' : ''"
              >
                {{ `\xa0\xa0${item.name}\xa0\xa0` }}
              </van-tag>
            </div>
          </div>
          <div style="display: flex; justify-content: space-between">
            <div style="margin-top: 10px">房源户型</div>
          </div>
          <div
            style="
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              margin-top: 12px;
            "
          >
            <div class="tags1" v-for="item in houseTypeList" :key="item.id">
              <van-tag
                round
                type="primary"
                color="#F4F5F7"
                text-color="#606060"
                size="large"
                @click="clickTag2(item.id)"
                :class="tagColor2 === item.id ? 'tag-click' : ''"
              >
                {{ `\xa0\xa0${item.name}\xa0\xa0` }}
              </van-tag>
            </div>
          </div>
          <div style="display: flex; justify-content: space-between">
            <div style="margin-top: 10px">宜居人数</div>
          </div>
          <!-- <van-collapse v-model="activeNames">
            <van-collapse-item title="宜居人数" name="1"> -->
          <div
            style="
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              margin-top: 12px;
            "
          >
            <div class="tags1" v-for="item in manNumList" :key="item.id">
              <van-tag
                round
                type="primary"
                color="#F4F5F7"
                text-color="#606060"
                size="large"
                @click="clickTag3(item.id)"
                :class="tagColor3 === item.id ? 'tag-click' : ''"
              >
                {{ `\xa0\xa0${item.name}\xa0\xa0` }}
              </van-tag>
            </div>
          </div>
          <!-- </van-collapse-item>
          </van-collapse> -->
          <div style="display: flex; justify-content: space-between">
            <div style="margin-top: 10px">床铺数量</div>
          </div>
          <div
            style="
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              margin-top: 12px;
            "
          >
            <div class="tags1" v-for="item in bedNumList" :key="item.id">
              <van-tag
                round
                type="primary"
                color="#F4F5F7"
                text-color="#606060"
                size="large"
                @click="clickTag4(item.id)"
                :class="tagColor4 === item.id ? 'tag-click' : ''"
              >
                {{ `\xa0\xa0${item.name}\xa0\xa0` }}
              </van-tag>
            </div>
          </div>
          <div
            style="
              padding: 12px 0px;
              display: flex;
              justify-content: space-between;
            "
          >
            <van-button style="width: 27%" block round @click="clear1">
              清空
            </van-button>
            <van-button
              style="width: 66%"
              type="danger"
              block
              round
              @click="onConfirm2"
            >
              确认
            </van-button>
          </div>
        </van-dropdown-item>
      </van-dropdown-menu>
    </div>

    <!-- 列表 -->
    <van-list
      class="vanList"
      v-model="loading"
      :finished="finished"
      offset="20"
      :immediate-check="false"
      finished-text="没有更多了 ~"
      @load="onLoad"
    >
      <div
        class="card"
        @click="gotoUrl('/hotels/hotelsXq/' + item.RoomId + '/' + Start)"
        v-for="item in list"
        :key="item.RoomId"
      >
        <img
          v-if="item.Imgs != null && item.Imgs != ''"
          :src="item.Imgs.split(',')[0]"
          style="width: 100%; height: 224px; border-radius: 8px"
        />
        <img
          v-else
          src="http://alycommunity.anluoyun.cn/lc-lcx/2023/6/35e14c95-10b.png"
          style="width: 100%; height: 224px; border-radius: 8px"
        />
        <div class="van-ellipsis Title">
          {{ item.RoomTitle }}
        </div>
        <div class="Title1">
          {{ item.RentalTypeDesc }}·{{ item.HouseTypeDesc }}·{{
            item.BedNum
          }}床·宜住{{ item.ManNum }}人
        </div>
        <div
          style="padding-left: 10px; display: flex"
          v-if="item.RServiceNames"
        >
          <div
            class="box"
            v-for="(item, index) in item.RServiceNames.split(',')"
            :key="index"
          >
            {{ item }}
          </div>
          <!-- <div class="box">有停车位</div>
          <div class="box">有洗衣机</div> -->
        </div>
        <div class="price">
          <span class="price1">¥</span>{{ item.DisPrice
          }}<span class="price1">/晚</span>
        </div>
      </div>
    </van-list>
    <!-- <van-button v-if="showBtn" @click="backTop" class="backTop"
      ><img
        src="https://dximg.dexian.ren/lczhsq/top.png"
        style="width: 13px; vertical-align: middle"
        alt=""
    /></van-button> -->
    <!-- 日期 -->
    <van-calendar
      v-model="show"
      type="range"
      :formatter="formatter"
      @confirm="onConfirm"
    />
  </div>
</template>

<script>
import { Wx_GetHotelRoomList } from "@/api/Hotel";
export default {
  // 组件名称
  name: "",
  // 组件参数 接收来自父组件的数据
  props: {},
  // 局部注册的组件
  components: {},
  // 组件状态值
  data() {
    return {
      list: [], //资讯列表
      loading: false, //加载
      finished: false, //数据完全加载结束为 true
      total: undefined, //数据条数
      radio: "",
      min: 0,
      max: 1000,
      // 双滑块模式时，值必须是数组
      huakuai: [0, 1000],
      switch1: false,
      switch2: false,
      sortMode: "1",
      Price: "1",
      checked: false,
      options: [{ text: "价格范围", value: "1" }],
      option2: [
        { text: "推荐排序", value: "1" },
        { text: "好评优先", value: "2" },
        { text: "低价优先", value: "3" },
        { text: "高价优先", value: "4" },
      ],
      PriceList: [
        { id: 1, name: "￥150以下" },
        { id: 2, name: "￥150-￥200" },
        { id: 3, name: "￥200-￥300" },
        { id: 4, name: "￥300-￥400" },
        { id: 5, name: "￥400-￥600" },
        { id: 6, name: "￥600-￥1000" },
      ],
      rentalTypeList: [
        { id: 1, name: "整套出租" },
        { id: 2, name: "单间房屋" },
        { id: 3, name: "合住房间" },
      ],
      houseTypeList: [
        { id: 1, name: "一室" },
        { id: 2, name: "两室" },
        { id: 3, name: "三室" },
        { id: 4, name: "四室及以上" },
      ],
      activeNames: ["1"],
      manNumList: [
        { id: 1, name: "1人" },
        { id: 2, name: "2人" },
        { id: 3, name: "3人" },
        { id: 4, name: "4人" },
        { id: 5, name: "5人" },
        { id: 6, name: "6人" },
        { id: 7, name: "7人" },
        { id: 8, name: "8人" },
      ],
      bedNumList: [
        { id: 1, name: "1张" },
        { id: 2, name: "2张" },
        { id: 3, name: "3张" },
        { id: 4, name: "4张" },
        { id: 5, name: "5张" },
        { id: 6, name: "6张" },
        { id: 7, name: "7张" },
        { id: 8, name: "8张" },
      ],
      search: "",
      show: false, // 时间
      showBtn: false,
      start: this.formatDate(new Date()),
      end: this.formatDate(
        new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
      ),
      Start: this.formatDateTime(new Date()),
      End: this.formatDateTime(
        new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
      ),
      tagColor: 0,
      tagColor1: 0,
      tagColor2: 0,
      tagColor3: 0,
      tagColor4: 0,
      listfrom: {
        page: 1,
        limit: 10,
        // useTo: "01",
        useTo: "",
        orgCode: undefined,
      },
    };
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {
    // 被侦听的变量count
    huakuai() {
      this.huakuai.forEach((i) => {
        if (i == 800) {
          console.log("max 发生了变化");
          // this.huakuai[1] = "无限";
        }
      });
    },
  },
  created() {
    // this.huakuai.forEach((i) => {
    //   if (i == 800) {
    //     console.log("huakuai 发生了变化");
    //   }
    // });
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);

    console.log(this.$route.params.name);
    // this.OrgName = this.$route.params.name;
    window.addEventListener("scroll", this.showBtnF, true);

    this.getList(undefined);
  },
  // 组件方法
  methods: {
    // 保留一位小数
    returnFloat1(value) {
      value = Math.round(parseFloat(value) * 10) / 10;
      if (value.toString().indexOf(".") < 0) {
        value = value.toString() + ".0";
      }
      return value;
    },
    getKey() {
      console.log(this.search);
      if (this.search == "") {
        this.list = [];
        this.getList();
      }
    },
    clearInput() {
      this.search = "";
      this.getList();
    },
    getSort(activeNames) {
      console.log(activeNames);
      this.list = [];
      this.listfrom.page = 1;
      this.getList();
    },
    // 获取分页列表
    getList(searchKey) {
      // console.log(this.Start, "this.Start");
      this.listfrom.sortMode = this.sortMode;
      this.listfrom.minPrice = this.huakuai[0];
      this.listfrom.maxPrice = this.huakuai[1];
      this.listfrom.rentalType = this.tagColor1; // 出租类型
      this.listfrom.houseType = this.tagColor2; // 房源户型
      this.listfrom.manNum = this.tagColor3; // 宜居人数
      this.listfrom.bedNum = this.tagColor4; // 床铺数量

      this.listfrom.inTime = this.$route.params.inTime;
      this.listfrom.searchKey = this.search;
      Wx_GetHotelRoomList(this.listfrom).then((res) => {
        if (res.data.code == 0) {
          // this.list = res.data.data;
          // this.finished = true;
          /**第二种 */
          let rows = res.data.data; //请求返回当页的列表
          // 加载状态结束

          if (rows == null || rows.length === 0) {
            // 加载结束
            console.log("结束");
            // this.list = rows;
            this.finished = true;
            return;
          } else {
            // 将新数据与老数据进行合并
            this.list = this.list.concat(rows);
            this.list.forEach((i) => {
              i.AllScore = this.returnFloat1(i.AllScore);
            });
            this.loading = false;
            // 如果列表数据条数>=总条数，不再触发滚动加载
            if (this.list.length >= res.data.length) {
              this.finished = true;
            }
          }
        } else {
          this.finished = true;
        }

        // if (res.data.code == 0) {
        //   if (res.data.count == 0) {
        //     this.finished = true;
        //     this.list = {};
        //   } else {
        //     this.list = res.data.data;
        //     this.total = res.data.count;
        //     this.finished = true;
        //     for (var i = 0; i < this.list.length; i++) {
        //       if (this.list[i].Imgs != null && this.list[i].Imgs != "") {
        //         var ImgsList = this.list[i].Imgs.split(',')
        //         this.list[i].Imgs = ImgsList
        //       }
        //     }
        //   }
        //   console.log(this.list.length);
        // }
      });
    },
    backTop() {
      // 开个定时器返回顶部时缓慢优雅一些，是 document.body.scrollTop = 0 的升级版
      const timer = setInterval(() => {
        // 页面顶部卷去高度
        let scrollTop =
          document.body.scrollTop || document.documentElement.scrollTop;
        // 高度控速 = 页面顶部卷去高度正值 / n ===> n 越大，返回越慢
        let ispeed = Math.floor(-scrollTop / 5);
        // 页面顶部卷去高度 + 高度控速 ===> 和不为零，定时器一直开启（不明白的同志可以尝试同时打印 scrollTop 和 ispeed）
        document.body.scrollTop = scrollTop + ispeed;
        // 页面顶部卷去高度 + 高度控速 ===> 和为零返回顶部，清除定时器
        if (scrollTop === 0) {
          clearInterval(timer);
        }
      }, 20);
    },
    showBtnF() {
      let scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;
      if (scrollTop > 100) {
        this.showBtn = true;
      } else {
        this.showBtn = false;
      }
    },
    // 下拉刷新
    onLoad() {
      this.loading = true;
      this.listfrom.page = this.listfrom.page + 1;
      this.getList(undefined);
    },
    clickTag(id) {
      console.log(id);
      if (id) {
        this.tagColor = id;
      }
      if (id == 1) {
        this.huakuai = [0, 150];
      }
      if (id == 2) {
        this.huakuai = [150, 200];
      }
      if (id == 3) {
        this.huakuai = [200, 300];
      }
      if (id == 4) {
        this.huakuai = [300, 400];
      }
      if (id == 5) {
        this.huakuai = [400, 600];
      }
      if (id == 6) {
        this.huakuai = [600, 1000];
      }
    },
    clickTag1(id) {
      console.log(id);
      if (id) {
        this.tagColor1 = id;
      }
    },
    clickTag2(id) {
      console.log(id);
      if (id) {
        this.tagColor2 = id;
      }
    },
    clickTag3(id) {
      console.log(id);
      if (id) {
        this.tagColor3 = id;
      }
    },
    clickTag4(id) {
      console.log(id);
      if (id) {
        this.tagColor4 = id;
      }
    },
    // 跳转
    gotoUrl(path) {
      if (window.__wxjs_environment === "miniprogram") {
        // 页面 不会重新加载
        window.wx.miniProgram.navigateTo({
          url: "../public/goto?path=" + path,
        });
      } else {
        // 页面会 重新加载(如:列表页 查看详情后会返回顶部)
        this.$router.push(path);
      }
    },
    onChange(value) {
      console.log("当前值：" + value);
    },
    onConfirm1() {
      this.$refs.item.toggle();
      console.log(this.huakuai);
      this.list = [];
      this.listfrom.page = 1;
      this.getList();
    },
    onConfirm2() {
      this.$refs.item1.toggle();
      console.log(this.tagColor1);
      console.log(this.tagColor2);
      console.log(this.tagColor3);
      console.log(this.tagColor4);
      this.list = [];
      this.listfrom.page = 1;
      this.getList();
    },
    // 搜索
    onSearch() {
      this.listfrom.page = 1;
      this.list = [];
      this.getList(undefined);
    },
    clear() {
      this.huakuai = [0, 1000];
      this.tagColor = 0;
    },
    clear1() {
      this.tagColor1 = 0;
      this.tagColor2 = 0;
      this.tagColor3 = 0;
      this.tagColor4 = 0;
    },
    onConfirm(date) {
      console.log(date, "date");
      const [start, end] = date;
      // var dayStart = this.Week(start.getDay()); //获取星期几
      // this.weekStart = dayStart;
      // var dayEnd = this.Week(end.getDay()); //获取星期几
      // this.weekEnd = dayEnd;
      this.show = false;
      this.start = this.formatDate(start); //08-03
      this.Start = this.formatDateTime(start); // 2023-08-10
      this.end = this.formatDate(end);
      this.End = this.formatDateTime(end);
      // this.DiffDay = this.getDiffDay(start, end);
      // this.date = `${this.formatDate(start)} - ${this.formatDate(end)}`;
    },
    formatDate(date) {
      if (date.getMonth() + 1 <= 9) {
        var month = `0${date.getMonth() + 1}`;
      } else {
        var month = `${date.getMonth() + 1}`;
      }
      if (date.getDate() <= 9) {
        var day = `0${date.getDate()}`;
      } else {
        var day = `${date.getDate()}`;
      }
      return `${month}.${day}`;
    },
    formatDateTime(val) {
      var timestamp = Date.parse(val);
      var date = new Date(timestamp);
      //获取年份
      var Y = date.getFullYear();
      //获取月份
      var M =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      //获取当日日期
      // console.log();
      var day = date.getDate();
      var D = day < 10 ? "0" + day : day;

      this.minDate = new Date(Y, M - 1, D);
      return Y + "-" + M + "-" + D;
    },
    formatter(day) {
      const month = day.date.getMonth() + 1;
      const date = day.date.getDate();
      // console.log(month, "month");
      // console.log(date, "date");
      if (month === 5) {
        if (date === 1) {
          day.topInfo = "劳动节";
        } else if (date === 4) {
          day.topInfo = "青年节";
        } else if (date === 11) {
          day.text = "今天";
        }
      }

      if (day.type === "start") {
        day.bottomInfo = "入住";
      } else if (day.type === "end") {
        day.bottomInfo = "离店";
      }
      return day;
    },
  },
};
</script> 

<style scoped>
@font-face {
  font-family: "Price";
  src: url(../../assets//font//OPPOSANS-B.TTF);
}
.content {
  background-color: #fefefe;
  /* height: 100vh; */
  padding-bottom: 60px;
}

.Top {
  background-color: #fff;
}
.top {
  display: flex;
  /* height: 20vh; */
  box-sizing: border-box;
  padding: 0 10px;
}
.van-search {
  width: 76%;
  padding: 10px 3px 10px 0;
}
.van-search__content {
  border-radius: 7px 0px 0px 7px;
  background-color: #f5f6f8;
}

.van-cell {
  line-height: 30px;
}

.day {
  width: 85px;
  height: 40px;
  background: #f5f6f8;
  border-radius: 0px 7px 7px 0px;
}

.day {
  margin: 10px 0;
  font-size: 11px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #666666;
  text-align: center;
  box-sizing: border-box;
  padding: 5px 0px 5px 9px;
}
.date {
  color: #1e1e1e;
  /* font-size: 10px; */
  font-family: OPPOSans;
  font-weight: 500;
}
.day div {
  line-height: 15px;
}

/deep/ .van-calendar__confirm {
  height: 42px;
}
/deep/ .van-calendar__day--start,
/deep/ .van-calendar__day--end {
  color: #1e1e1e;
  background-color: #f8de4b;
}

/deep/ .van-calendar__day--middle::after {
  background-color: #f8de4b;
  opacity: 0.19;
}
/deep/ .van-calendar__day--middle {
  color: #1e1e1e;
}
/deep/ .van-button--danger {
  background: #fede00;
  border: 1px solid #fede00;
  color: #1e1e1e;
}
/deep/ .van-dropdown-menu__bar {
  box-shadow: none;
}
/deep/ .van-dropdown-menu__title {
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #1e1e1e;
}

/deep/ .van-dropdown-menu__title::after {
  position: absolute;
  top: 38%;
  right: -11px;
  border: none;
  -webkit-transform: none;
  transform: none;
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  background-image: url("https://dximg.dexian.ren/lczhsq/Dian.png");
  background-repeat: no-repeat;
  background-size: 35% 35%;
  margin-top: 4px;
}
/deep/ .van-dropdown-menu__title--active {
  color: #1e1e1e !important;
}
.van-slider {
  width: 93%;
  height: 2px;
  margin: 30px 11px;
}

/deep/ .van-popup {
  box-sizing: border-box;
  padding: 0px 16px;
}

.tags {
  /* display: flex; */
  width: 30%;
}
.tags1 {
  width: 25%;
}
/deep/ .van-tag--large {
  padding: 8px 0px;
  font-size: 11px;
}

/deep/ .van-tag {
  width: 90%;
  margin-bottom: 10px;
  text-align: center;
  /* margin: auto; */
  display: inline-block;
}
.tag-click {
  border: 1px solid #1e90ff !important;
  color: #1e90ff !important;
}

[class*="van-hairline"]::after {
  border: none;
}
/deep/ .van-calendar__header {
  box-shadow: none;
}

.card:first-child {
  margin-top: 12px;
}
/* 列表 */
.card {
  /* height: 350px; */
  width: 95%;
  background-color: #fff;
  box-shadow: 0px 0px 9px 0px rgb(0 0 0 / 10%);
  border-radius: 8px;
  margin: auto;
  margin-bottom: 12px;
  box-sizing: border-box;
  padding: 6px 6px 10px 6px;
}

.Title {
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #1e1e1e;
  margin-top: 4px;
}
.Title1 {
  padding-left: 10px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #1e1e1e;
  margin-top: 4px;
}

.box {
  margin-right: 6px;
  /* width: 42px; */
  /* height: 20px; */
  box-sizing: border-box;
  padding: 3px 5px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  font-size: 10px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #666666;
  text-align: center;
  margin-top: 6px;
}
.price {
  font-family: "Price";
  color: #fe411b;
  font-size: 20px;
  margin-top: 6px;
  padding-left: 10px;
}
.price1 {
  font-family: PingFang SC;
  font-size: 12px;
  vertical-align: bottom;
}
.price2 {
  font-family: PingFang SC;
  font-size: 14px;
  vertical-align: bottom;
}
.backTop {
  position: fixed;
  bottom: 40px;
  right: 17px;
  width: 35px;
  height: 35px;
  background: rgba(255, 255, 255, 0.68);
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.06);
  border-radius: 50%;
}
</style>